const envs = {
  dev: {
    cognito: {
      USER_POOL_ID:
        process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_p79oYNfaI',
      APP_CLIENT_ID:
        process.env.REACT_APP_COGNITO_CLIENT_ID || '4bru035a0l6aaopbhib58j6ja1',
    },
    google: {
      MAPS: 'AIzaSyATayOuqk4nwjdhkWwc8rT6sYKSGi8LMWE',
      GTM_ID: 'GTM-PDCP8PV',
      GTM_AUTH: 'YfvxMWph5ZY8alQeTFPh6A',
      GTM_PREV: 'env-3',
    },
    api: {
      HOST: 'api.dev.thinkhomewise.com',
      REALTY: 'https://api.dev.thinkhomewise.com/realty',
      CUSTOMER: 'https://api.dev.thinkhomewise.com/customer',
      CONTACT: 'https://api.dev.thinkhomewise.com/realty/contact',
    },
    repliers: {
      CDN: 'https://cdn.repliers.io',
    },
    baseUrl:
      process.env.REACT_APP_BASE_PATH === '/'
        ? 'https://web.dev.thinkhomewise.com'
        : '',
  },
  staging: {
    cognito: {
      USER_POOL_ID:
        process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_DWQCKvXCo',
      APP_CLIENT_ID:
        process.env.REACT_APP_COGNITO_CLIENT_ID || '5439n54p7pck0f59ou1jbd659o',
    },
    google: {
      MAPS: 'AIzaSyCj22L_OvFvLQgwCVm3uIPdmkWiih7wXBA',
      GTM_ID: 'GTM-PDCP8PV',
      GTM_AUTH: 'H2qnGHeVfpbkZS36rwZlcQ',
      GTM_PREV: 'env-4',
    },
    api: {
      HOST: 'api.staging.thinkhomewise.com',
      REALTY: 'https://api.staging.thinkhomewise.com/realty',
      CUSTOMER: 'https://api.staging.thinkhomewise.com/customer',
      CONTACT: 'https://api.staging.thinkhomewise.com/realty/contact',
    },
    repliers: {
      CDN: 'https://cdn.repliers.io',
    },
    baseUrl:
      process.env.REACT_APP_BASE_PATH === '/'
        ? 'https://web.staging.thinkhomewise.com'
        : '',
  },
  prod: {
    cognito: {
      USER_POOL_ID:
        process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_Jw9soIcjP',
      APP_CLIENT_ID:
        process.env.REACT_APP_COGNITO_CLIENT_ID || '2g0l19pv9h4gteot4g5f56p9eo',
    },
    google: {
      MAPS: 'AIzaSyDPrjuNKSqXxYXJ6RYapzUvFiOeYJM3Y00',
      GTM_ID: 'GTM-PDCP8PV',
      GTM_AUTH: 'hLvSFNcC_zMTSevTpVpXTA',
      GTM_PREV: 'env-1',
    },
    api: {
      HOST: 'api.thinkhomewise.com',
      REALTY: 'https://api.thinkhomewise.com/realty',
      CUSTOMER: 'https://api.thinkhomewise.com/customer',
      CONTACT: 'https://api.thinkhomewise.com/realty/contact',
    },
    repliers: {
      CDN: 'https://cdn.repliers.io',
    },
    baseUrl:
      process.env.REACT_APP_BASE_PATH === '/'
        ? 'https://thinkhomewise.com'
        : '',
  },
}

const config = {
  ...envs[process.env.REACT_APP_STAGE],
  // Add version from package.json
  version: require('../package.json').version,
}

export default config
