import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'components/common/CustomLink'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { CardActionArea } from '@mui/material'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

// import IconButton from '@mui/material/IconButton'
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
// import FavoriteIcon from '@mui/icons-material/Favorite'
import BedIcon from '@mui/icons-material/Bed'
import BathtubIcon from '@mui/icons-material/Bathtub'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import ThumbnailGallery from './ThumbnailGallery'

import line from '../../assets/separator.png'
import placeholder from '../../assets/placeholder.png'
import AuthContext from 'store/auth/authContext'

import {
  formatCurrency,
  formatDetailPagePath,
  formatListingName,
} from '../../utils/formats'
import { soldStatuses, leasedStatuses } from '../../utils/constants'

const imageFormatOptions = new URLSearchParams({
  w: 800, // adjust as needed
  f: 'webp', // fastest format for web
})

const DataCard = (props) => {
  const { data, boardId } = props
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const [activeSession, setActiveSession] = useState(
    localStorage.getItem('activeSession') === 'true' || user?.activeSession
  )
  const isPublic =
    data?.permissions?.displayAddressOnInternet === 'Y' &&
    data?.permissions?.displayPublic === 'Y'

  useEffect(() => {
    if (user?.activeSession !== undefined) setActiveSession(user?.activeSession)
  }, [user?.activeSession])

  return !isPublic && !activeSession ? (
    <Card
      className="hwCard"
      sx={{
        display: !isPublic && !activeSession ? 'grid' : 'block',
      }}
    >
      {!isPublic && !activeSession && (
        <Button
          variant="contained"
          size="small"
          sx={{
            borderRadius: '10px',
            position: 'absolute',
            zIndex: '20',
            width: 'auto',
            alignSelf: 'center',
            justifySelf: 'center',
          }}
        >
          <Link
            to={`/auth/login?redirect=${formatDetailPagePath(
              data?.address,
              data?.mlsNumber,
              data?.boardId || boardId
            )}`}
            style={{ color: `white` }}
          >
            LOGIN TO VIEW
          </Link>
        </Button>
      )}
      <div
        style={{
          filter: !isPublic && !activeSession ? 'blur(5px)' : 'none',
        }}
      >
        <span style={{ position: `relative` }}>
          <CardActionArea
            onClick={() => {
              navigate(
                `/auth/login?redirect=${formatDetailPagePath(
                  data?.address,
                  data?.mlsNumber,
                  data?.boardId || boardId
                )}`
              )
            }}
            sx={{ height: 235 }}
          >
            {/* <p
              style={{
                marginTop: `10px`,
                textAlign: `center`,
              }}
            >
              <span>A verified account is required.</span>
              <br />
              <span>
                Please&nbsp;
                <Link
                  to={`/auth/login?redirect=${formatDetailPagePath(
                    data?.address,
                    data?.mlsNumber,
                    data?.boardId
                  )}`}
                  style={{ textDecoration: `underline` }}
                >
                  login
                </Link>
                &nbsp;to view this property.
              </span>
            </p> */}
            <CardMedia
              component="img"
              className="media"
              image={
                data?.images?.length >= 1
                  ? `https://cdn.repliers.io/${data.images[1]}?${imageFormatOptions}`
                  : placeholder
              }
              alt={data?.address?.streetName?.substring(0, 25) || ''}
            />
          </CardActionArea>
          <Stack
            direction="row"
            sx={{
              marginTop: `-30px`,
              padding: `0 5px`,
              zIndex: `10`,
              position: 'relative',
            }}
            justifyContent="space-between"
          >
            {data.type === 'Sale' &&
              (soldStatuses.includes(data.lastStatus) ? (
                <Chip
                  label={
                    data?.lastStatus === 'Sc' ? 'Sold Conditional' : 'Sold'
                  }
                  color="sold"
                  size="small"
                />
              ) : (
                <Chip label="For Sale" color="forSale" size="small" />
              ))}
            {data.type === 'Lease' &&
              (leasedStatuses.includes(data.lastStatus) ? (
                <Chip label="Leased" color="sold" size="small" />
              ) : (
                <Chip label="For Lease" color="forLease" size="small" />
              ))}
            {data.details.virtualTourUrl && (
              <Chip
                label="Virtual Tour"
                color="primary"
                size="small"
                component="a"
                href={`/real-estate/auth/login?redirect=${formatDetailPagePath(
                  data?.address,
                  data?.mlsNumber,
                  data?.boardId || boardId
                )}`}
                clickable
              />
            )}
          </Stack>
        </span>
        <CardActionArea>
          <Link
            to={`/auth/login?redirect=${formatDetailPagePath(
              data?.address,
              data?.mlsNumber,
              data?.boardId || boardId
            )}`}
          >
            <CardContent className="content">
              <div style={{ position: `relative` }}>
                <h2>
                  <Link
                    to={`/auth/new-account?redirect=${formatDetailPagePath(
                      data?.address,
                      data?.mlsNumber,
                      data?.boardId || boardId
                    )}`}
                    className="link"
                  >
                    Join
                  </Link>
                  &nbsp;or&nbsp;
                  <Link
                    to={`/auth/login?redirect=${formatDetailPagePath(
                      data?.address,
                      data?.mlsNumber,
                      data?.boardId || boardId
                    )}`}
                    className="link"
                  >
                    Login
                  </Link>
                  &nbsp;to view.
                </h2>
                <h3 className="truncate">
                  <span className="highlight">
                    {formatListingName(data.address)}
                  </span>
                </h3>
                <h4 className="truncate">
                  {data.address.city.substring(0, 100)},{' '}
                  {data.address.area.substring(0, 100)}{' '}
                  {data.address.neighborhood &&
                    '-' + data.address.neighborhood.substring(0, 100)}
                </h4>
                {/* <IconButton
                    aria-label="Save"
                    color="primary"
                    sx={{ position: `absolute`, top: 0, right: 0 }}
                  >
                    {props.saved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton> */}
                <img
                  src={line}
                  width="24"
                  height="2"
                  alt=""
                  style={{ margin: `10px 0px` }}
                />
                <Stack direction="row" sx={{ margin: `5px 0px 10px` }}>
                  {data.details.numBedrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Beds'}
                      icon={<BedIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numBathrooms && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Baths'}
                      icon={<BathtubIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                  {data.details.numGarageSpaces && (
                    <Chip
                      variant="outlined"
                      color="chip"
                      size="small"
                      label={'- Garage'}
                      icon={<DirectionsCarIcon />}
                      sx={{ border: `0px` }}
                    />
                  )}
                </Stack>
                <h5 className="truncate">
                  {data?.mlsNumber && `MLS® ${data?.mlsNumber}`}
                  {data?.office?.brokerageName &&
                    ` - ${data?.office?.brokerageName}`}
                </h5>
              </div>
            </CardContent>
          </Link>
        </CardActionArea>
      </div>
    </Card>
  ) : (
    <Card className="hwCard">
      <span style={{ position: `relative` }}>
        <ThumbnailGallery data={data} boardId={boardId} />
        <Stack
          direction="row"
          sx={{
            marginTop: `-30px`,
            padding: `0 5px`,
            zIndex: `10`,
            position: 'relative',
          }}
          justifyContent="space-between"
        >
          {data.type === 'Sale' &&
            (soldStatuses.includes(data.lastStatus) ? (
              <Chip label="Sold" color="sold" size="small" />
            ) : (
              <Chip label="For Sale" color="forSale" size="small" />
            ))}
          {data.type === 'Lease' &&
            (leasedStatuses.includes(data.lastStatus) ? (
              <Chip label="Leased" color="sold" size="small" />
            ) : (
              <Chip label="For Lease" color="forLease" size="small" />
            ))}
          {data.details.virtualTourUrl && (
            <Chip
              label="Virtual Tour"
              color="primary"
              size="small"
              component="a"
              href={data.details.virtualTourUrl}
              clickable
            />
          )}
        </Stack>
      </span>
      <CardActionArea>
        <Link
          to={formatDetailPagePath(
            data?.address,
            data?.mlsNumber,
            data?.boardId || boardId
          )}
        >
          <CardContent className="content">
            <div style={{ position: `relative` }}>
              <h2>{formatCurrency(parseFloat(data.listPrice))}</h2>
              <h3 className="truncate">
                <span className="highlight">
                  {formatListingName(data.address)}
                </span>
              </h3>
              <h4 className="truncate">
                {data.address.city.substring(0, 100)},{' '}
                {data.address.area.substring(0, 100)}{' '}
                {data.address.neighborhood &&
                  '-' + data.address.neighborhood.substring(0, 100)}
              </h4>
              {/* <IconButton
                  aria-label="Save"
                  color="primary"
                  sx={{ position: `absolute`, top: 0, right: 0 }}
                >
                  {props.saved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton> */}
              <img
                src={line}
                width="24"
                height="2"
                alt=""
                style={{ margin: `10px 0px` }}
              />
              <Stack direction="row" sx={{ margin: `5px 0px 10px` }}>
                {data.details.numBedrooms && (
                  <Chip
                    variant="outlined"
                    color="chip"
                    size="small"
                    label={data.details.numBedrooms + ' Beds'}
                    icon={<BedIcon />}
                    sx={{ border: `0px` }}
                  />
                )}
                {data.details.numBathrooms && (
                  <Chip
                    variant="outlined"
                    color="chip"
                    size="small"
                    label={data.details.numBathrooms + ' Baths'}
                    icon={<BathtubIcon />}
                    sx={{ border: `0px` }}
                  />
                )}
                {data.details.numGarageSpaces && (
                  <Chip
                    variant="outlined"
                    color="chip"
                    size="small"
                    label={parseFloat(data.details.numGarageSpaces) + ' Garage'}
                    icon={<DirectionsCarIcon />}
                    sx={{ border: `0px` }}
                  />
                )}
              </Stack>
              <h5 className="truncate">
                {data?.mlsNumber && `MLS® ${data?.mlsNumber}`}
                {data?.office?.brokerageName &&
                  ` - ${data?.office?.brokerageName}`}
              </h5>
            </div>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  )
}

export default DataCard
